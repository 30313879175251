.AppearingText {
  position: relative;
}

.AppearingText-background {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  transition: width 0.333s ease-out 1.333s, visibility 0s linear 1.666s;
}
