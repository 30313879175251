@import-normalize;

html, body {
  overflow: hidden;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2 {
  font-weight: 900;
  margin: 0;
}

a {
  color: #2196f3;
  text-decoration: none;
}

button {
  color: white;
  border: none;
  font-size: 16px;
  border-radius: 4px;
  padding: 6px 10px;
  transition: background-color 0.333s ease-in-out;
  cursor: pointer;
}

button:focus {
  outline: none;
}

.button-purple {
  background-color: #673ab7;
}

.button-purple:hover {
  background-color: #320b86;
}

.button-purple:disabled {
  background-color: #9a67ea;
}

.button-indigo {
  background-color: #3f51b5;
}

.button-indigo:hover {
  background-color: #002984;
}

.button-indigo:disabled {
  background-color: #757de8;
}

canvas {
  width: 100vw;
}

.card {
  background-color: white;
  padding: 12px 16px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.Scene {
  position: relative;
  height: 100vh;
}

.Scene > header {
  position: absolute;
  top: 6%;
  left: 6%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 400px;
}

.Scene > article {
  position: absolute;
  bottom: 6%;
  right: 6%;
  max-width: 264px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Scene > section {
  position: absolute;
  bottom: 6%;
  left: 6%;
  max-width: 300px;
}

.NotFound {
  position: relative;
  height: 100vh;
}

.NotFound > h1 {
  font-size: 64px;
}

.SocialMedia-link {
  color: black;
  font-size: 20px;
}

.mobile-visibility {
  display: none;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: white;
}

.hover-underline:hover {
  cursor: pointer;
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {

  html {
    overflow: hidden;
  }

  .mobile-full {
    top: 0 !important;
    height: 100vh !important;
    max-height: 100vh !important;
  }

  .mobile-none {
    display: none !important;
  }

  .mobile-visibility {
    display: block;
    padding: 3px;
    transition: opacity 0.333s ease-in-out, color 0.666s ease-in-out;
  }

  .mobile-visible > div > header,
  .mobile-visible > div > article {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.333s ease-in-out;
  }

  .mobile-hidden > div > header,
  .mobile-hidden > div > article {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.333s ease-in-out, visibility 0.333s linear 0.333s;
  }

  .card {
    box-shadow: none;
  }

  .Scene {
    height: 100%;
  }

  .Scene > header {
    top: 24px;
    left: 0;
    right: 0;
    max-height: 170px;
    max-width: 100%;
    overflow-y: auto;
  }

  .Scene > article {
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100%;
  }

  .Scene > section {
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100%;
  }

  .card-polyhedrons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    max-height: 120px;
  }

  .card-polyhedrons > button {
    width: calc(50% - 0.5rem);
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .mobile-margin-top-fix {
    margin-top: 1.5rem !important;
  }
}

.relative {
  position: relative;
}

.grow {
  flex-grow: 1;
}

.w100 {
  width: 100%;
}

.cursor-drag {
  cursor: grab;
}

.github-button-wrapper {
  min-height: 20px;
  margin: 0;
  transition: opacity 0.33s linear 1.333s;
}
